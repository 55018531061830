import React from 'react';

import { color } from 'src/utils/mui';

export default (props) => {
    return (
        <svg {...props} width="195" height="62" viewBox="0 0 195 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M89.5299 29.5V37.67C88.4682 37.3923 87.3772 37.2412 86.2799 37.22C81.9399 37.22 78.4399 39.84 78.4399 45.64V61.64H69.4399V29.1H78.4399V33.33C79.3219 31.9985 80.5282 30.9135 81.9452 30.177C83.3623 29.4404 84.9435 29.0767 86.5399 29.12C87.5459 29.1572 88.5466 29.2844 89.5299 29.5V29.5Z" fill={color('themeColor')}/>
                <path d="M159.21 45.36C159.21 49.675 157.496 53.8134 154.445 56.8646C151.393 59.9158 147.255 61.63 142.94 61.63C138.625 61.63 134.487 59.9158 131.435 56.8646C128.384 53.8134 126.67 49.675 126.67 45.36C126.67 43.2234 127.091 41.1077 127.909 39.1337C128.726 37.1597 129.925 35.3661 131.435 33.8553C132.946 32.3445 134.74 31.1461 136.714 30.3284C138.688 29.5108 140.803 29.09 142.94 29.09C145.077 29.09 147.192 29.5108 149.166 30.3284C151.14 31.1461 152.934 32.3445 154.445 33.8553C155.955 35.3661 157.154 37.1597 157.972 39.1337C158.789 41.1077 159.21 43.2234 159.21 45.36V45.36ZM135.48 45.36C135.48 47.3385 136.266 49.236 137.665 50.635C139.064 52.034 140.962 52.82 142.94 52.82C144.919 52.82 146.816 52.034 148.215 50.635C149.614 49.236 150.4 47.3385 150.4 45.36C150.4 43.3815 149.614 41.484 148.215 40.085C146.816 38.6859 144.919 37.9 142.94 37.9C140.962 37.9 139.064 38.6859 137.665 40.085C136.266 41.484 135.48 43.3815 135.48 45.36Z" fill={color('themeColor')}/>
                <path d="M25.86 29L17.54 44.6L9.2 29.03L0 29L17.57 61.55L35.07 29H25.86Z" fill={color('themeColor')}/>
                <path d="M118.5 29L110.18 44.6L101.84 29.03L92.64 29L110.21 61.55L127.71 29H118.5Z" fill={color('themeColor')}/>
                <path d="M183.72 52.77C182.347 53.7502 180.685 54.2398 179 54.16C177.069 54.2563 175.173 53.6211 173.69 52.381C172.207 51.1409 171.247 49.3872 171 47.47H194.29C194.41 46.47 194.48 44.98 194.48 44.28C194.484 42.2604 194.087 40.2601 193.312 38.3953C192.537 36.5304 191.399 34.8381 189.964 33.4166C188.53 31.9951 186.827 30.8726 184.955 30.1145C183.083 29.3563 181.079 28.9775 179.06 29C176.924 28.9638 174.803 29.3557 172.821 30.1524C170.84 30.949 169.037 32.1344 167.521 33.6387C166.005 35.1429 164.805 36.9355 163.992 38.9108C163.18 40.8862 162.771 43.0042 162.79 45.14C162.763 47.309 163.171 49.4614 163.988 51.4706C164.806 53.4797 166.017 55.3049 167.551 56.8388C169.085 58.3726 170.91 59.584 172.919 60.4017C174.928 61.2194 177.081 61.6268 179.25 61.6C182.114 61.6032 184.937 60.9172 187.48 59.6L183.72 52.77ZM178.72 36.3C180.279 36.225 181.809 36.7418 183.003 37.7469C184.197 38.7519 184.968 40.171 185.16 41.72H171.47C171.84 40.1115 172.766 38.6856 174.086 37.6939C175.405 36.7021 177.032 36.2085 178.68 36.3H178.72Z" fill={color('themeColor')}/>
                <path d="M54.94 52.77C53.5643 53.7517 51.8981 54.2414 50.21 54.16C48.2793 54.2564 46.3833 53.6211 44.9004 52.3811C43.4175 51.141 42.4568 49.3873 42.21 47.47H65.52C65.6415 46.4108 65.7083 45.3461 65.72 44.28C65.7242 42.2588 65.3264 40.2568 64.55 38.3906C63.7735 36.5245 62.6338 34.8312 61.1971 33.4094C59.7604 31.9877 58.0555 30.8656 56.1813 30.1087C54.3071 29.3517 52.3011 28.9748 50.28 29C48.141 28.9625 46.0163 29.3542 44.0313 30.1522C42.0464 30.9501 40.2416 32.138 38.7238 33.6456C37.206 35.1531 36.0059 36.9498 35.1945 38.9293C34.3831 40.9088 33.977 43.0308 34 45.17C33.9732 47.3399 34.3809 49.4932 35.1992 51.503C36.0175 53.5128 37.2298 55.3385 38.7647 56.8724C40.2996 58.4064 42.126 59.6176 44.1364 60.4347C46.1467 61.2518 48.3002 61.6582 50.47 61.63C53.3341 61.6333 56.1569 60.9473 58.7 59.63L54.94 52.77ZM49.9 36.3C51.4591 36.2251 52.9889 36.7419 54.1831 37.7469C55.3772 38.752 56.1477 40.1711 56.34 41.72H42.69C43.06 40.1116 43.9864 38.6856 45.3057 37.6939C46.6249 36.7022 48.2521 36.2085 49.9 36.3Z" fill={color('themeColor')}/>
            </g>
            <path d="M6.64003 0H1.28003V14H3.62003V8.6H6.64003C9.22003 8.6 11.12 6.78 11.12 4.28C11.12 1.82 9.22003 0 6.64003 0ZM6.64003 6.36H3.62003V2.24H6.64003C7.86003 2.24 8.76003 3.12 8.76003 4.28C8.76003 5.46 7.86003 6.36 6.64003 6.36Z" fill={color('themeColor')}/>
            <path d="M16.6971 4.02C13.8371 4.02 11.6371 6.22 11.6371 9.12C11.6371 12.02 13.8371 14.2 16.6971 14.2C19.5571 14.2 21.7771 12.02 21.7771 9.12C21.7771 6.22 19.5571 4.02 16.6971 4.02ZM16.6971 6.16C18.3171 6.16 19.5771 7.44 19.5771 9.12C19.5771 10.78 18.3171 12.06 16.6971 12.06C15.0771 12.06 13.8371 10.78 13.8371 9.12C13.8371 7.44 15.0771 6.16 16.6971 6.16Z" fill={color('themeColor')}/>
            <path d="M25.7917 14H27.5517L29.8717 7.66L32.1917 14H33.9717L37.5117 4.22H35.1118L33.0717 10.58L30.7517 4.22H28.9917L26.6917 10.58L24.6517 4.22H22.2517L25.7917 14Z" fill={color('themeColor')}/>
            <path d="M47.8242 8.9C47.8242 6.04 45.6843 4.02 43.0443 4.02C40.2243 4.02 38.0042 6.22 38.0042 9.06C38.0042 11.98 40.2243 14.2 43.0843 14.2C45.4843 14.2 47.3443 12.84 47.7043 10.78H45.4642C45.2642 11.62 44.3242 12.18 43.1242 12.18C41.5442 12.18 40.5442 11.28 40.2642 9.84H47.7642C47.8042 9.66 47.8242 9.16 47.8242 8.9ZM40.3242 7.94C40.6442 6.74 41.6042 6.04 42.9242 6.04C44.2642 6.04 45.2043 6.82 45.4043 7.94H40.3242Z" fill={color('themeColor')}/>
            <path d="M54.5361 4.02C53.3761 4.02 52.4761 4.58 51.8961 5.52V4.22H49.6761V14H51.8961V8.78C51.8961 7.02 53.0561 6.04 54.4761 6.04C54.7961 6.04 55.1361 6.1 55.4561 6.18V4.14C55.1561 4.08 54.8361 4.02 54.5361 4.02Z" fill={color('themeColor')}/>
            <path d="M65.6172 8.9C65.6172 6.04 63.4772 4.02 60.8372 4.02C58.0172 4.02 55.7972 6.22 55.7972 9.06C55.7972 11.98 58.0172 14.2 60.8772 14.2C63.2772 14.2 65.1372 12.84 65.4972 10.78H63.2572C63.0572 11.62 62.1172 12.18 60.9172 12.18C59.3372 12.18 58.3372 11.28 58.0572 9.84H65.5572C65.5972 9.66 65.6172 9.16 65.6172 8.9ZM58.1172 7.94C58.4372 6.74 59.3972 6.04 60.7172 6.04C62.0572 6.04 62.9972 6.82 63.1972 7.94H58.1172Z" fill={color('themeColor')}/>
            <path d="M74.9138 0V5.94C74.2138 4.72 73.0138 4.02 71.4538 4.02C68.8538 4.02 66.7738 6.28 66.7738 9.1C66.7738 11.92 68.8538 14.2 71.4538 14.2C73.0138 14.2 74.2138 13.5 74.9138 12.28V14H77.1538V0H74.9138ZM71.9338 12.08C70.2738 12.08 68.9738 10.76 68.9738 9.1C68.9738 7.46 70.2738 6.14 71.9338 6.14C73.6338 6.14 74.9138 7.42 74.9138 9.1C74.9138 10.8 73.6338 12.08 71.9338 12.08Z" fill={color('themeColor')}/>
            <path d="M89.6144 4.02C88.0344 4.02 86.8344 4.74 86.1344 5.98V0H83.9144V14H86.1344V12.24C86.8344 13.48 88.0344 14.2 89.6144 14.2C92.2144 14.2 94.2744 11.92 94.2744 9.1C94.2744 6.28 92.2144 4.02 89.6144 4.02ZM89.1344 12.08C87.4144 12.08 86.1344 10.8 86.1344 9.1C86.1344 7.42 87.4144 6.14 89.1344 6.14C90.7944 6.14 92.0944 7.46 92.0944 9.1C92.0944 10.76 90.7944 12.08 89.1344 12.08Z" fill={color('themeColor')}/>
            <path d="M102.372 4.22L99.7522 10.88L97.1322 4.22H94.7322L98.6122 13.48L96.8522 17.44H99.2522L104.772 4.22H102.372Z" fill={color('themeColor')}/>
            <defs>
                <clipPath id="clip0">
                    <rect width="194.5" height="32.63" fill="white" transform="translate(0 29)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
