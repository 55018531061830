import moment from 'moment';
import { MONTHS } from './constants';

export const makePluralString = (number, singleForm, pluralForm = `${singleForm}s`) => {
    return number === 1 ? singleForm : pluralForm;
};

export const secondsToTimeObject = (timeInSeconds) => {
    let seconds = parseInt(timeInSeconds, 10);

    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return {
        days,
        hours,
        minutes,
        seconds
    };
};

export const generateSingleCodeValue = ({ footer, header, body }) => (
    `${header && `${header}\n`}${body && `${body}\n`}${footer}`
);

export const generateLanguagesArray = (codeChallenge, activeLanguage) => {
    const langs = [
        { name: 'NodeJs', mode: 'text/javascript' },
        { name: 'PHP', mode: 'text/x-php' },
        { name: 'C++', mode: 'text/x-c++src' },
        { name: 'Python3', mode: 'text/x-python' },
        { name: 'Ruby', mode: 'text/x-ruby' },
        { name: 'Go', mode: 'text/x-go' },
        { name: 'C', mode: 'text/x-csrc' },
        { name: 'Java', mode: 'text/x-java' },
        { name: 'C#', mode: 'text/x-csharp' }
    ];
    if (activeLanguage) {
        const { mode } = langs.find(({ name }) => name.toLowerCase() === activeLanguage.toLowerCase());
        return mode;
    }
    const languages = codeChallenge.reduce((langsArr, { language }) => [...langsArr, language], []);

    return langs.filter((lang) => {
        if (languages.some(item => lang.name.toLowerCase() === item)) {
            return lang;
        }
        return null;
    });
};

export const formFullExpirationStatus = (expirationTimeStatus, lang) => {
    if (!expirationTimeStatus) return null;
    const { expired, numberOfDays, numberOfHours, numberOfMinutes } = expirationTimeStatus;
    let returnedString = '';
    let locales;
    switch (lang) {
        case 'es': locales = ['Caducado', 'y', 'día', 'hora', 'minuto']; break;
        default: locales = ['Expired', 'and', 'day', 'hour', 'minute'];
    }
    if (expired) {
        return locales[0];
    }
    if (numberOfDays) {
        returnedString = `${numberOfDays} ${makePluralString(numberOfDays, locales[2])} `;
        if (numberOfHours) {
            returnedString += `${locales[1]} ${numberOfHours} ${makePluralString(numberOfHours, locales[3])}`;
        }
    } else if (numberOfHours) {
        returnedString = `${numberOfHours} ${makePluralString(numberOfHours, locales[3])} `;
        if (numberOfMinutes) {
            returnedString += `${locales[1]} ${numberOfMinutes} ${makePluralString(numberOfMinutes, locales[4])}`;
        }
    } else if (numberOfMinutes) {
        returnedString = `${numberOfMinutes} ${locales[4]}s`;
    }
    return returnedString;
};

export const formMobileExpirationStatus = (expirationTimeStatus, lang) => {
    if (!expirationTimeStatus) return null;
    const { expired, numberOfDays, numberOfHours, numberOfMinutes } = expirationTimeStatus;
    let vocabulary;
    switch (lang) {
        case 'es': vocabulary = ['Caducado', 'restante']; break;
        default: vocabulary = ['Expired', 'remaining'];
    }
    let returnedString = '';
    if (expired) {
        return vocabulary[0];
    }
    if (numberOfDays) {
        returnedString = `${numberOfDays}d`;
    } else if (numberOfHours) {
        returnedString = `${numberOfHours}h`;
    } else if (numberOfMinutes) {
        returnedString = `${numberOfMinutes}m`;
    }
    return `${returnedString} ${vocabulary[1]}`;
};

export const getInitials = (str = '') => {
    return str.split(' ').reduce((string, item) => {
        if (item && string.length < 2) {
            string += item[0].toUpperCase();
        }
        return string;
    }, '');
};

export const getShortDate = (date) => {
    const thisDate = date ? new Date(date) : new Date();

    return `${MONTHS[thisDate.getMonth()]} ${thisDate.getDate()}`;
};

export const formatSeconds = (time) => {
    return moment.utc(moment.duration(time, 'seconds').as('milliseconds')).format('mm:ss');
};

export const nl2brCustom = (string) => {
    return string?.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2') || null;
};
