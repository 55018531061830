import { lazy } from 'react';

import { retry } from 'src/utils/routing';
import { newUserStore } from './store';

export default {
    name: 'job-page',
    path: '/job-page/:slugAudition/:hashCode',
    activate: (info) => {
        const { hashCode } = info.route.params;
        newUserStore.loadAudition(hashCode);
    },
    component: () => lazy(() => retry(() => import('./index')))
};
