import { createContext } from 'react';
import { action, observable, computed } from 'mobx';
import { IsEmail, IsNotEmpty, Equals, MaxLength } from 'class-validator';
import { fromPromise } from 'mobx-utils';

import { authApi } from 'src/api/auth';
import { dashbooardApi } from 'src/api/dashboard';
import { useLink } from 'src/utils/routing';
import { Validatable } from 'src/models/Validatable';
import { routerStore } from 'src/store/router';

import { MagicLinkStore } from '../magic_link/store';

class NewUser extends Validatable {
    @observable.ref req = fromPromise.resolve(null);

    @observable preview = false;

    @observable loader = false;

    @IsEmail({}, { message: 'Email is not valid' })
    @IsNotEmpty({ message: 'This field is required' })
    @MaxLength(180, { message: 'Maximum length is 180 symbols' })
    @observable email = '';

    @IsNotEmpty({ message: 'This field is required' })
    @MaxLength(160, { message: 'Maximum length is 160 symbols' })
    @observable userName = '';

    @Equals(true, { message: 'You must read and agree to Terms of Use' })
    @observable agreeWithTC = false;

    @action send = async () => {
        this.validateName();
        this.validateEmail();
        this.validateTC();
        if (this.hasError) return;
        this.loader = true;
        try {
            const { data, success } = await authApi.applyToJob(
                {
                    email: this.email,
                    fullName: this.userName,
                    hashCode: routerStore.params.hashCode
                }
            );
            if (!success || !data) return;
            if (data.magicLinkSent) {
                MagicLinkStore.setEmail(this.email);
                MagicLinkStore.setLoginSent(true);
                useLink('login', {})();
            } else {
                localStorage.setItem('token', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                window.location.href = '/job-dashboard';
            }
        } catch (err) {
            if (err.response.status === 403) {
                useLink('not_found_link', {})();
            }
        } finally {
            this.loader = false;
        }
    };

    @action loadAudition = async (hashCode, isPreview = false) => {
        this.preview = Boolean(isPreview);
        this.req = fromPromise(dashbooardApi.audition(hashCode));
    };

    @computed get hasError() {
        return Boolean(this.getErrors(['email', 'userName', 'agreeWithTC']).length || !this.email.length);
    }

    setEmail = this.createSetterFromComponent('email');

    setName = this.createSetterFromComponent('userName');

    setAgreeWithTC = this.createBooleanSetterFromComponent('agreeWithTC');

    validateEmail = this.validateField('email');

    validateName = this.validateField('userName');

    validateTC = this.validateField('agreeWithTC');
}

export const newUserStore = new NewUser();
export const newUserStoreCtx = createContext(newUserStore);
