import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RouterStoreCtx } from 'src/store/router';
import { AuthStoreCtx } from 'src/store/auth';
import { useTranslation } from 'react-i18next';
import { useWidth } from '../../../utils/mui';
import MobileNavMenu from './mobile_sidebar_menu';

import { Logo } from './header_logo';
import { MenuPopover } from './menu_popover';
import { InterviewHeader } from './diff_headers/interview_header';

import { useStyles } from './styles';
import VervoeLogo from '../../../assets/VervoeLogo';
import { AppStoreCtx } from '../../../store/app';
import { InterviewPageStoreCtx } from '../../../pages/interview/store';

export default observer(() => {
    const { route } = useContext(RouterStoreCtx);
    const { isIFrame, isWebView } = useContext(AppStoreCtx);
    const { header, previewLabelWrapper, headerPreview, headerGrey } = useStyles({ isIFrame });
    const [right, setPosition] = useState(false);
    const { logo, auth, company, appStartColor } = useContext(AuthStoreCtx);
    const { assessmentLogo, interview } = useContext(InterviewPageStoreCtx);
    const { isLess } = useWidth('xs');
    const { t } = useTranslation();

    const toggleDrawer = () => setPosition(!right);

    let otherHeaderType = null;
    let showPreview = false;
    let hideMenu = false;
    let hideLogo = false;
    let greyMenu = false;
    let className = '';
    switch (route.name) {
        case 'job-page-preview':
            hideMenu = true;
            showPreview = true;
            className = headerPreview;
            break;
        case 'interview':
            hideLogo = isLess;
            showPreview = false;
            hideMenu = true;
            otherHeaderType = <InterviewHeader />;
            break;
        case 'index':
        case 'equip_check':
            greyMenu = true;
            break;
        case 'not_found':
            showPreview = false;
            hideLogo = true;
            otherHeaderType = <VervoeLogo />;
            break;
        case 'not_found_link':
            showPreview = false;
            hideMenu = true;
            hideLogo = true;
            otherHeaderType = <VervoeLogo />;
            break;
        case 'attachment':
            hideMenu = true;
            hideLogo = true;
            break;
        default:
            break;
    }

    hideLogo = hideLogo || (isIFrame && !(interview && interview.onboarding)) || isWebView;
    hideMenu = hideMenu || isIFrame || isWebView;

    return (
        <>
            {
                showPreview
                && (
                    <div className={previewLabelWrapper}>
                        {t('This is a preview of what the application page will look like for candidates')}
                    </div>
                )
            }
            <header className={`${header} ${className} ${greyMenu ? headerGrey : ''}`}>
                {
                    !hideLogo && (
                        <Logo
                            disabled={route.name === 'interview'}
                            appStartColor={appStartColor}
                            isOnboardingAssessment={interview && interview.onboarding}
                            assessmentLogo={assessmentLogo}
                            logo={logo}
                            company={company}
                        />
                    )
                }
                { otherHeaderType }
                {
                    (!hideMenu && auth) && <MenuPopover onClick={isLess ? () => { toggleDrawer(); } : null}/>
                }
            </header>
            {
                isLess && (
                    <MobileNavMenu
                        open={right}
                        close={() => { toggleDrawer(); }}
                    />
                )
            }
        </>
    );
});
