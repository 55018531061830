import { makeStyles } from '@mui/styles';
import { buildVariant } from 'src/utils/mui';

export const useStyles = makeStyles({
    menuIcon: {
        marginLeft: -10
    },
    backIconButton: {
        marginLeft: -5
    },
    backIcon: {
        fontSize: 22
    },
    paperDrawer: {
        zIndex: 1001,
        width: '100vw'
    },
    headerWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    drawerHeader: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 60,
        boxShadow: '0 1px 11px 0 rgba(0, 0, 0, 0.08)',
        padding: '16px 12px'
    },
    completedLaterButton: {
        paddingLeft: 10,
        paddingRight: 10
    },
    completedLaterLabel: {
        ...buildVariant(700, 14)
    },
    menuWrapper: {
        padding: 16
    },
    statusWrapper: {
        ...buildVariant(600, 11, 'textSecondary'),
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        textTransform: 'uppercase',
        display: 'flex'
    },
    statusWrapperThemeEmphasis: {
        ...buildVariant(700, 11, 'themeColor')
    },
    headerLeftWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    ttInfoWrapper: {
        ...buildVariant(500, 11, 'textSecondary'),
        lineHeight: 2,
        marginBottom: 15
    },
    stepsWrapper: {
        height: 'calc(100vh - 120px)',
        overflow: 'auto',
        overflowX: 'hidden'
    },
    counter: {
        paddingRight: 15
    }
});
