import { action, observable } from 'mobx';
import React, { createContext } from 'react';
import CheckMark from '../assets/checkmark.svg';

const getSnackbarAction = actionType => (({
    done: <img src={CheckMark} alt="Success" />
})[actionType]);

export class AppStore {
    @observable snackbarOpened = false;

    @observable snackbarText = false;

    @observable snackbarAction = null;

    @observable snackbarAutoHideDuration = 4000;

    @observable isIFrame = window.location !== window.parent.location;

    @observable isWebView = window.navigator.userAgent.includes('wv')
        || (!window.navigator.userAgent.includes('Safari') && !window.navigator.standalone);

    constructor() {
        if ((this.isIFrame || this.isWebView) && window.zE) {
            window.zE('webWidget', 'hide');
        }
    }

    @action openSnackbar = (text, snackbarAction = null, snackbarAutoHideDuration = 4000) => {
        this.snackbarOpened = true;
        this.snackbarText = text;
        this.snackbarAction = getSnackbarAction(snackbarAction) || null;
        this.snackbarAutoHideDuration = snackbarAutoHideDuration;
    };

    @action closeSnackbar = () => {
        this.snackbarOpened = false;
        this.snackbarText = '';
        this.snackbarAction = null;
    };
}

export const appStore = new AppStore();

export const AppStoreCtx = createContext(appStore);
