import { WrapperRequest } from './wrapperRequest';

export class Interview extends WrapperRequest {
    startInterview(id) {
        return this.get(`candidate/user-auditions/${id}/start`);
    }

    getInterviewQuestions(id) {
        return this.get(`candidate/user-auditions/${id}/question-results`);
    }

    getInterviewQuestion(id) {
        return this.get(`candidate/question-results/${id}`);
    }

    saveAnswer(id, answerData) {
        return this.post(`candidate/question-results/${id}/save-answer`, answerData);
    }

    saveSimulatorAnswer(id, answer_id) {
        return this.patch(`candidate/question-results/${id}/cs-simulator-answer-results/${answer_id}/check`);
    }

    setTimeSpentOnQuestion(id, amount) {
        return this.patch(`candidate/question-results/${id}/increase-time`, { amount });
    }

    setQuestionAsSeen(id) {
        return this.patch(`candidate/question-results/${id}/first-time`);
    }

    setQuestionNetworkAsSeen(id) {
        return this.post(`candidate/question-results/${id}/linux-simulation-answered`);
    }

    startRecording(id) {
        return this.patch(`candidate/question-results/${id}/start-recording`);
    }

    startUploading(id) {
        return this.patch(`candidate/question-results/${id}/start-uploading`);
    }

    stopUploading(id) {
        return this.patch(`candidate/question-results/${id}/stop-uploading`);
    }

    openExternalFile(id) {
        return this.patch(`candidate/question-results/${id}/open-external-file`);
    }

    uploadFileAnswer(id, data, settings) {
        return this.post(`candidate/question-results/${id}/upload-file-answer`, data, settings);
    }

    loadInterview(interviewId) {
        return this.get('interviewScript', { interviewId });
    }

    submitInterview(id, isAutoCompleteInterview) {
        return this.patch(`candidate/user-auditions/${id}/complete-interview`, { isAutoCompleteInterview });
    }

    startTimedQuestion(id) {
        return this.patch(`candidate/question-results/${id}/start`);
    }

    setViewPermissionForDocsQuestion(questionResultId) {
        return this.post('google-drive/set-view-permission', { questionResultId });
    }

    getTestCases(questionId) {
        return this.get(`candidate/question-results/${questionId}/test-cases`);
    }

    saveIntermediateResult(questionId, codeChallengeId, data) {
        return this.post(`candidate/question-results/${questionId}/code-challenge-question-results/${codeChallengeId}`, data);
    }

    runTestCases(id, codeChallengeId, data) {
        return this.post(`candidate/question-results/${id}/code-challenge-question-results/${codeChallengeId}/run-code`, data);
    }

    setCSATSeen = (id) => {
        return this.patch(`candidate/user-auditions/${id}/candidate-feedback/skip`);
    }

    setCandidateFeedback = (id, score, comment) => {
        return this.post(`candidate/user-auditions/${id}/feedback`, { score, comment });
    }

    setEEOCSurvey = (uuid, gender, race, veteranStatus) => {
        return this.post(`candidate/user-auditions/${uuid}/eeoc`, { gender, race, veteranStatus });
    }

    setEEOCSurveySkipped = (uuid) => {
        return this.post(`candidate/user-auditions/${uuid}/eeoc/skip`);
    }

    completeTypingTest = (uuid) => {
        return this.patch(`candidate/typing-test-results/${uuid}/complete`);
    }

    getProspectActivityFeedForSalesSimulation = (id) => {
        return this.get(`candidate/prospect/${id}/history`);
    }

    getQAProductLinkToken = (id) => {
        return this.get(`candidate/question-results/${id}/qa-product/token`);
    }

    saveMarketingSimulationTask = (taskID) => {
        return this.post(`candidate/candidate-tasks/${taskID}/save-task`);
    }

    getScreeningQuestions(id) {
        return this.get(`candidate/user-auditions/${id}/screening-questions`);
    }

    saveScreeningAnswer(id, answerData) {
        return this.patch(`candidate/screening-question-results/${id}`, answerData);
    }
}

export const interviewApi = new Interview();
