import { lazy } from 'react';
import { retry } from 'src/utils/routing';

import { verifyEmailStore } from './store';

export default {
    name: 'verify-email',
    path: '/verify-email?token',
    activate: () => {
        verifyEmailStore.loadData();
    },
    component: () => lazy(() => retry(() => import('./index')))
};
