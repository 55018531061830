import { createContext } from 'react';
import { observable, computed, action } from 'mobx';
import { IsEmail, IsNotEmpty } from 'class-validator';

import { authApi } from 'src/api/auth';
import { Validatable } from 'src/models/Validatable';

class MagicLink extends Validatable {
    @IsNotEmpty({ message: 'This field is required' })
    @IsEmail({}, { message: 'Email is not valid' })
    @observable email = '';

    @observable linkExpired = false;

    @observable loginSent = false;

    @observable loginError = '';

    @observable loadingPage = true;

    @observable pending = false;

    @action setLoginSent = (value) => {
        this.loginSent = value;
    };

    @action setEmail = (value) => {
        this.email = value;
    };

    @action send = async () => {
        this.emailValidate();
        if (this.hasError) return;
        this.pending = true;
        try {
            const res = await authApi.sendMagicLink({
                email: this.email
            });
            if (res.success) {
                this.loginSent = true;
                this.pending = false;
            }
        } catch (e) {
            this.loginError = 'Email not found. Please check the email entered and try again.';
            this.pending = false;
        }
    };

    @computed get hasError() {
        return Boolean(this.getErrors(['email']).length || !this.email.length);
    }

    emailSetter = this.createSetterFromComponent('email');

    emailValidate = this.validateField('email');
}

export const MagicLinkStore = new MagicLink();
export const MagicLinkStoreCtx = createContext(MagicLinkStore);
