import { lazy } from 'react';

import { newUserStore } from './store';

export default {
    name: 'job-page-preview',
    path: '/job-page/:slugAudition/:hashCode/preview',
    activate: (info) => {
        const { hashCode } = info.route.params;
        newUserStore.loadAudition(hashCode, true);
    },
    component: () => lazy(() => import('./index'))
};
