import { createContext } from 'react';
import { observable, action } from 'mobx';

import { dashbooardApi } from 'src/api/dashboard';
import { InterviewPageStore } from '../interview/store';
import { authStore } from '../../store/auth';

class EquipCheckPage {
    @observable audition = null;

    @action getAuditionData = async (auditionId) => {
        const res = await dashbooardApi.userAudition(auditionId);
        if (res.success) {
            this.audition = res.data;
            if (res.data.brand) InterviewPageStore.setAssessmentBranding(res.data.brand);
            authStore.clearAppStartColor();
        }
    };
}
export const equipCheckPageStore = new EquipCheckPage();
export const EquipCheckPageStoreCtx = createContext(equipCheckPageStore);
