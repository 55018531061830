import { lazy } from 'react';

import { retry } from 'src/utils/routing';
import { homePageStore } from './store';

export default {
    name: 'index',
    secured: true,
    activate: () => {
        homePageStore.fetchOpportunities();
    },
    deactivate: () => {
        homePageStore.cleanData();
    },
    path: '/job-dashboard',
    component: () => lazy(() => retry(() => import('./index')))
};
