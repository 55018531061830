import React, { useState } from 'react';
import { SubmitDialog } from 'src/components/submit_dialog';
import { useWidth } from 'src/utils/mui';
import { CSATDialog } from 'src/components/csat_dialog';
import { EEOCDialog } from 'src/components/eeoc_dialog';
import { DesktopHeader } from './desktop_header';
import { MobileHeader } from './mobile_header';

import { CompleteLaterDialog } from './complete_later_dialog';

export const InterviewHeader = () => {
    const [completeLaterDialogOpened, setCompleteLaterDialogOpened] = useState(false);
    const [submitDialogOpened, setSubmitDialogOpened] = useState(false);
    const [csatDialogOpened, setCsatDialogOpened] = useState(false);
    const [eeocDialogOpened, setEeocDialogOpened] = useState(false);

    const { isLess: isMobile } = useWidth('xs');

    const handleCompleteLaterDialog = () => {
        setCompleteLaterDialogOpened(!completeLaterDialogOpened);
    };

    const handleSubmitDialog = () => {
        setSubmitDialogOpened(!submitDialogOpened);
    };

    return (
        <>
            {
                isMobile
                    ? (
                        <MobileHeader
                            handleSubmitDialog={handleSubmitDialog}
                            handleCompleteLaterDialog={handleCompleteLaterDialog}
                        />
                    )
                    : (
                        <DesktopHeader
                            handleCompleteLaterDialog={handleCompleteLaterDialog}
                            handleSubmitDialog={handleSubmitDialog}
                        />
                    )
            }
            <CompleteLaterDialog
                open={completeLaterDialogOpened}
                handleOpen={handleCompleteLaterDialog}
            />
            <SubmitDialog
                open={submitDialogOpened}
                handleOpen={handleSubmitDialog}
                setCsatDialogOpened={setCsatDialogOpened}
                setEeocDialogOpened={setEeocDialogOpened}
            />
            <CSATDialog
                open={csatDialogOpened}
            />
            <EEOCDialog
                open={eeocDialogOpened}
                setEeocDialogOpened={setEeocDialogOpened}
                setCsatDialogOpened={setCsatDialogOpened}
            />
        </>
    );
};
