import React from 'react';
import clsx from 'clsx';
import { getParams } from 'src/utils/routing';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { RequiredLabel } from 'src/components/required_label';
import { makePluralString } from 'src/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { CompletedIcon } from './completedIcon';
import TimerIcon from './timer.svg';

export const StepperItem = ({ index, opened, active, item, changeQuestion, disabled }) => {
    const {
        wrapper, wrapperHoverable, wrapperLabel,
        wrapperActive, number,
        description, currentQuestionStyle,
        completedQuestion, descriptionOpened,
        additionalInfo, timerIcon, requiredLabel,
        wrapperActiveBackground, touchRippleRoot,
        wrapperLabelOpened
    } = useStyles({});
    const { t } = useTranslation();

    const {
        questionDescription, id, questionHasAnswer, question,
        type, timedQuestion, simulationCandidateQuestionInstructions
    } = item;
    const timeLimit = question && question.timeLimit;
    const required = question && question.required;
    const current = +getParams().id === id || active;

    return (
        <>
            <Button
                id={`step_${id}`}
                disabled={disabled}
                onClick={() => { changeQuestion(index); }}
                classes={{
                    root: clsx(wrapper, !disabled && wrapperHoverable, current && wrapperActive, current && opened && wrapperActiveBackground),
                    label: clsx(wrapperLabel, opened && wrapperLabelOpened)
                }}
                TouchRippleProps={{
                    classes: {
                        root: touchRippleRoot
                    }
                }}
            >
                <div className={number}>
                    {t('Q{{index}}', { index })}
                </div>
                {
                    current && <div className={currentQuestionStyle} />
                }
                <div className={clsx(description, opened && descriptionOpened)}>
                    {
                        type === 14
                            ? t('Your feedback')
                            : (
                                <>
                                    { !timedQuestion
                                        ? ((questionDescription?.replace(/<(?:.|\n)*?>/gm, ' ') || simulationCandidateQuestionInstructions) || t('This question contains media'))
                                        : t("This question is timed, you'll have {{timeLimit}} {{timeUnits}} to complete it.",
                                            { timeLimit, timeUnits: makePluralString(timeLimit, t('minute')) }) }
                                </>
                            )
                    }
                </div>
                {
                    !current && questionHasAnswer
                        && <CompletedIcon className={completedQuestion} />
                }
                {
                    opened && !questionHasAnswer && (
                        <div className={additionalInfo}>
                            {
                                required
                                    && <RequiredLabel className={requiredLabel} />
                            }
                            {
                                timedQuestion
                                && (
                                    <Tooltip title={t('Timed question ({{timeLimit}} {{timeUnits}})',
                                        { timeLimit, timeUnits: makePluralString(timeLimit, t('min')) })}
                                    >
                                        <img src={TimerIcon} className={timerIcon} alt="⌛" />
                                    </Tooltip>
                                )
                            }
                        </div>
                    )
                }
            </Button>
        </>
    );
};
