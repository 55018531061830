import { WrapperRequest } from './wrapperRequest';

export class User extends WrapperRequest {
    info() {
        return this.get('candidate/info');
    }

    changeEmail(data) {
        return this.post('candidate/profile/request-change-email', data);
    }

    verifyEmail(data) {
        return this.post('public/candidate/profile/change-email', data);
    }

    removeImage(id) {
        return this.delete(`candidate/profiles/${id}/photo`);
    }

    getUserData(id) {
        return this.get(`candidate/profiles/${id}`);
    }

    updateUserData(id, data) {
        return this.patch(`candidate/profiles/${id}`, data);
    }

    getUserSkills(id) {
        return this.get(`candidate/profiles/${id}/skills`);
    }

    addSkills(id, skill) {
        return this.post(`candidate/profiles/${id}/skills`, skill);
    }

    removeSkill(id, skillId) {
        return this.delete(`candidate/profiles/${id}/skills/${skillId}`);
    }

    addPhoto(id, data) {
        return this.post(`candidate/profiles/${id}/photo`, data);
    }

    addCvFile(id, data) {
        return this.post(`candidate/profiles/${id}/resume`, data);
    }

    addVideo(id, data) {
        return this.patch(`candidate/profiles/${id}/ziggeo-token`, data);
    }

    fetchInvitationLink() {
        return this.get('candidate/magiclink');
    }
}

export const userApi = new User();
