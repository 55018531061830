import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import detect from 'browser-detect';
import * as qs from 'query-string';
import { authApi } from 'src/api/auth';
import { userApi } from 'src/api/user';
import { luckyorangePushcustomData } from 'src/utils/luckyorange';
import moment from 'moment';
import esLocale from 'moment/locale/es';
import i18n from '../i18n';

const browser = detect();

class Auth {
    @observable company = {};

    @observable currentUser = {};

    @observable userSegmentTraits = {};

    @observable network_error = false;

    @observable auth = false;

    @observable isChrome = false;

    @observable isSafari = false;

    @observable invitationLink = '';

    @observable appStartColor = '#ffffff'; // this is to remove flickering during the first app render.

    @action clearAppStartColor = () => {
        this.appStartColor = null;
    }

    @action switchOn = async () => {
        if (browser.name === 'ie') Promise.resolve(true);
        const parsed = qs.parse(window.location.search);
        const { token } = parsed;

        if (token && localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        }

        this.auth = Boolean(localStorage.getItem('token'));
        this.isChrome = Boolean((browser.name === 'chrome' || navigator.userAgent.match('CriOS')) && !navigator.userAgent.includes("Edg"));
        this.isSafari = Boolean(browser.name === 'safari');
        const res = await authApi.fetchCompany();
        this.company = res;
        const { candidateAppLanguage } = res;
        await i18n.changeLanguage(candidateAppLanguage);
        moment.locale(candidateAppLanguage, [esLocale]);
        if (this.auth) {
            try {
                await this.updateUserInfo();
                this.getInvitationLink();
            } catch (e) {
                console.log('Something went wrong');
            }
        }
    };

    @action updateUserInfo = async () => {
        const { data: { user, userSegmentTraits } } = await userApi.info();
        this.currentUser = user;
        this.userSegmentTraits = userSegmentTraits;
        luckyorangePushcustomData(userSegmentTraits);
    };

    @action errorNetwork = () => {
        this.network_error = true;
    };

    @action magicLinkLogin = async (data) => {
        const res = await authApi.loginUser(data);
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
        }

        if (res.success) {
            this.auth = true;
            localStorage.setItem('token', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            this.updateUserInfo();
            this.getInvitationLink();
        }
        return res;
    };

    @action getInvitationLink = async () => {
        const { data: { link } } = await userApi.fetchInvitationLink();
        this.invitationLink = link;
    };

    @action logOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        this.auth = false;
        window.location.href = '/job-dashboard';
    };

    @computed get logo() {
        if (this.company.company_image_url) {
            return this.company.company_image_url[0];
        }
        return '';
    }
}

export const authStore = new Auth();
export const AuthStoreCtx = createContext(authStore);

export const authMiddleWare = routes => () => (toState, fromState, done) => {
    if (localStorage.getItem('token')) {
        authStore.auth = true;
    }

    const nextRoute = routes[toState.name];

    if (nextRoute.secured && !authStore.auth) {
        if (window.location.pathname.split('/')[1] === 'job-page') {
            done();
            return;
        }
        return Promise.reject({
            redirect: {
                name: 'login'
            }
        });
    }

    done();
};
