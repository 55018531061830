import { lazy } from 'react';

import { retry } from 'src/utils/routing';

import { equipCheckPageStore } from './store';

export default {
    name: 'equip_check',
    path: '/equip-check/:auditionId',
    secured: true,
    component: () => lazy(() => retry(() => import('./index.js'))),
    activate: (info) => {
        const { auditionId } = info.route.params;
        equipCheckPageStore.getAuditionData(auditionId);
    }
};
