import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import { useWidth } from 'src/utils/mui';
import { ScreeningContentStoreCtx } from 'src/pages/interview/screeningStore';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const SubmitButton = observer(({ handleSubmitDialog, isAllCompleted }) => {
    const { submitButton, submitButtonLabel } = useStyles({});
    const { isLess: isMobile } = useWidth('xs');
    const { t } = useTranslation();
    const { currentQuestion: currentScreeningQuestion, screeningStatus } = useContext(ScreeningContentStoreCtx);

    let buttonVariant = isMobile ? 'text' : 'contained';
    let buttonColor = 'inherit';
    if (isAllCompleted) {
        buttonColor = 'primary';
        buttonVariant = 'contained';
    }

    if (currentScreeningQuestion || screeningStatus === 'screeningFailed') return null;

    return (
        <Button
            onClick={handleSubmitDialog}
            classes={{ root: submitButton, label: submitButtonLabel }}
            variant={buttonVariant}
            color={buttonColor}
        >
            {t('Submit')}
        </Button>
    );
});
