import React from 'react';

import { useWidth } from 'src/utils/mui';

import Footer from './footer';
import Header from './header';

export default ({ children }) => {
    const { isLess } = useWidth('xs');

    return (
        <>
            <Header />
            {children}
            {!isLess && (<Footer />)}
        </>
    );
};
