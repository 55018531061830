import { createContext } from 'react';
import { observable, action } from 'mobx';
import { useLink } from 'src/utils/routing';

class SubmittedInterview {
    @observable interview = null;
    @observable profile = {};

    @action setInterview = (newInterview) => {
        const goHome = useLink('index', {});
        if (!newInterview || !newInterview.completed) goHome();
        this.interview = newInterview;
    };

    @action setProfile = (newProfile) => {
        this.profile = newProfile;
    };
}

export const SubmittedInterviewStore = new SubmittedInterview();
export const SubmittedInterviewStoreCtx = createContext(SubmittedInterviewStore);
