import { lazy } from 'react';
import { authStore } from 'src/store/auth';
import { retry } from 'src/utils/routing';
import { useLink } from '../../utils/routing';
import { dashbooardApi } from '../../api/dashboard';
import { SubmittedInterviewStore } from './store';
import { userApi } from '../../api/user';

export default {
    name: 'submitted_interview',
    path: '/:interviewId/success',
    activate: async (info) => {
        const goToStart = useLink('index', {});
        const { interviewId } = info.route.params;
        const { currentUser } = authStore;
        const { candidateCompanyId } = currentUser;
        try {
            const result = await Promise.all([dashbooardApi.userAudition(interviewId), userApi.getUserData(candidateCompanyId)]);
            SubmittedInterviewStore.setInterview(result[0].data);
            SubmittedInterviewStore.setProfile(result[1].data);
        } catch (e) {
            goToStart();
        }
    },
    component: () => lazy(() => retry(() => import('./index')))
};
