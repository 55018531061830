import { makeStyles } from '@mui/styles';
import { buildVariant } from '../../../../../../utils/mui';

export const useStyles = makeStyles({
    listItem: {
        paddingLeft: '40px',
        height: '72px'
    },
    listItemText: {
        ...buildVariant(700, 14, 'textEmphasis')
    },
    listItemIcon: {
        marginRight: 10
    }
});
