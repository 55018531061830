import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const HelpIcon = () => (
    <SvgIcon>
        <path
            fill="#676A78"
            fillRule="evenodd"
            d="M22.5 18h.75a.75.75 0 0 1 .75.75v1.5A3.75 3.75
            0 0 1 20.25 24H3.75A3.75 3.75 0 0 1 0 20.25v-1.5A.75.75
            0 0 1 .75 18h.75V8.25A2.25 2.25 0 0 1 3.75 6H7.5a.75.75
            0 0 1 0 1.5H3.75a.75.75 0 0 0-.75.75V18h6.416a.75.75 0
            0 1 .651.378 2.226 2.226 0 0 0 3.866 0 .75.75 0 0
            1 .651-.378H21v-2.25a.75.75 0 1 1 1.5 0V18zM12 21a3.726
            3.726 0 0 1-2.988-1.5H1.5v.75a2.25 2.25 0 0 0 2.25
            2.25h16.5a2.25 2.25 0 0 0 2.25-2.25v-.75h-7.512A3.726
            3.726 0 0 1 12 21zm-.552-18.535A7.113 7.113 0 0 1
            18.874.292a7.142 7.142 0 0 1-4.034 13.703 7.043 7.043
            0 0 1-1.197-.482l-4.432 1.21a.75.75 0 0 1-.87-1.054l1.91-3.877a7.113
            7.113 0 0 1 1.197-7.327zm1.137.979a5.613 5.613 0 0 0-.83 6.048.75.75
            0 0 1-.005.65l-1.328 2.696 3.116-.851a.75.75 0 0 1 .57.072c.368.21.758.378
            1.147.495a5.642 5.642 0 0 0 3.134-10.84 5.613 5.613 0 0 0-5.804 1.73zm3.172
            2.213a.75.75 0 1 1-1.5 0 2.625 2.625 0 1 1 2.625 2.625.75.75 0 1 1 0-1.5 1.125
            1.125 0 1 0-1.125-1.125zM17 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        />
    </SvgIcon>
);
