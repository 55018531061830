import { createContext } from 'react';
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';

import { userApi } from 'src/api/user';
import { routerStore } from 'src/store/router';

import { authStore } from 'src/store/auth';

class VerifyEmail {
    @observable.ref req = fromPromise.resolve(null);

    @action loadData = async () => {
        await userApi.verifyEmail({ token: routerStore.route.params.token });
        authStore.updateUserInfo();
    }
}

export const verifyEmailStore = new VerifyEmail();
export const verifyEmailStoreCtx = createContext(verifyEmailStore);
