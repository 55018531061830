import React from 'react';

export const CompletedIcon = ({ fill = '#66CBCB', className = '' }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
            <defs>
                <path id="a" d="M3.363 6.714L7.75 2.057a.583.583 0 0 1 .815-.03.562.562 0 0 1 .031.803L3.779 7.943a.583.583 0 0 1-.853-.007L1.398 6.258a.562.562 0 0 1 .044-.802.583.583 0 0 1 .814.043l1.107 1.215z"/>
            </defs>
            <g fill="none" fillRule="evenodd">
                <path fill={fill} d="M6 0C2.688 0 0 2.688 0 6s2.688 6 6 6 6-2.688 6-6-2.688-6-6-6z"/>
                <use fill="#FFF" transform="translate(1.2 1.2)" xlinkHref="#a"/>
            </g>
        </svg>

    );
};
