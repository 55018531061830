import { lazy } from 'react';

import { retry } from 'src/utils/routing';
import { routerStore } from 'src/store/router';

export default {
    name: 'admin-login',
    path: '/admin-login?token',
    activate: () => {
        localStorage.setItem('token', routerStore.route.params.token);
        localStorage.setItem('refreshToken', routerStore.route.params.token);
        window.location.href = '/';
    },
    component: () => lazy(() => retry(() => import('./index')))
};
