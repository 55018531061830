import axios from 'axios';
import { authStore } from '../store/auth';

const apiURL = `${process.env.REACT_APP_API_URL}/api`;
const company = process.env.REACT_APP_COMPANY_NAME || window.location.hostname.split('.')[0];

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Company-Slug': company,
    Authorization: `Bearer ${localStorage.getItem('token')}`
};

export class WrapperRequest {
    post = (url, params = {}, settings = {}) => WrapperRequest.makeRequest('post', url, params, settings);

    patch = (url, params = {}, settings = {}) => WrapperRequest.makeRequest('patch', url, params, settings);

    get = (url, params = {}, settings = {}) => WrapperRequest.makeRequest('get', url, params, settings);

    delete = (url, params = {}, settings = {}) => WrapperRequest.makeRequest('delete', url, params, settings);

    static getNewToken() {
        const instanceAdditional = axios.create({ apiURL });
        const refreshToken = localStorage.getItem('refreshToken');
        const config = {
            method: 'post',
            url: 'public/refresh/token',
            baseURL: apiURL,
            headers,
            data: {
                refreshToken
            }
        };
        return instanceAdditional(config)
            .then((response) => {
                const { data } = response;
                if (data.success) {
                    return data;
                }
                return null;
            })
            .catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
            });
    }

    static refreshToken() {
        return WrapperRequest.getNewToken()
            .then((tokenData) => {
                if (tokenData) {
                    headers.Authorization = `Bearer ${tokenData.accessToken}`;
                    localStorage.setItem('refreshToken', tokenData.refreshToken);
                    localStorage.setItem('token', tokenData.accessToken);
                    return true;
                }
            });
    }

    static makeRequest(method, url, parameters, settings) {
        if (!navigator.onLine) {
            authStore.errorNetwork();
        }
        const { cancelToken, onUploadProgress } = settings;

        const instance = axios.create({ apiURL });
        const config = {
            method,
            baseURL: apiURL,
            url,
            headers,
            [method === 'get' ? 'params' : 'data']: parameters,
            cancelToken,
            onUploadProgress
        };

        instance.interceptors.response.use((response) => {
            if (response.data && response.data.data) {
                const { data } = response.data;
                if (data.redirectLink) {
                    window.location.replace(data.redirectLink);
                    return { success: false };
                }
            }
            return response.data || response;
        }, (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    return WrapperRequest.refreshToken()
                        .then((success) => {
                            if (success) {
                                return instance.request(config);
                            }
                        });
                }
                if (error.response && error.response.status === 503 && !config.__isRetryRequest) {
                    config.__isRetryRequest = true;
                    return instance.request(config);
                }
            }
            // console.log(error.request || 'Error', error.message || 'Something went wrong.' || error.config);
            return Promise.reject(error);
        });
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return instance.request(config);
    }
}
