import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Button from '@mui/material/Button';
import { CustomDialog } from 'src/components/custom_dialog';
import { InterviewPageStoreCtx } from 'src/pages/interview/store';
import { ScreeningContentStoreCtx } from 'src/pages/interview/screeningStore';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation, Trans } from 'react-i18next';
import { useWidth } from '../../utils/mui';
import { FEEDBACK, NO_ANSWER } from '../../utils/constants';

import { useStyles } from './styles';

export const SubmitDialog = observer(({
    open, handleOpen, setCsatDialogOpened, setEeocDialogOpened
}) => {
    const {
        flatButton, dialogPaper,
        circularProgress, titleStyle,
        contentStyle, buttonStyle, errorMessage
    } = useStyles({});
    const { t } = useTranslation();
    const {
        questions, incompletedRequiredQuestionsIndexes,
        incompletedQuestionsIndexes, submitInterview,
        changeQuestion, interview, currentQuestion
    } = useContext(InterviewPageStoreCtx);
    const { countAnswered } = useContext(ScreeningContentStoreCtx);
    const { isLess: mobile } = useWidth('xs');
    const [submitProcess, setSubmitProcess] = useState(false);
    const [showError, setShowError] = useState(false);

    const closeDialog = () => {
        if (submitProcess) return;
        handleOpen();
    };

    const handleSubmitInterview = async () => {
        try {
            setSubmitProcess(true);
            setShowError(false);
            const response = await submitInterview();
            if (response?.deadlineError) {
                closeDialog();
                return;
            }
            window.parent.postMessage({ message: 'auditionComplete' }, '*');

            if (interview.submitUrl && interview.candidateFeedbackSeen && !interview.eeocEnabled) return;
            closeDialog();
            setSubmitProcess(false);
            if (interview && interview.eeocEnabled) {
                setEeocDialogOpened(true);
                return;
            }
            if (interview && !interview.candidateFeedbackSeen) setCsatDialogOpened(true);
        } catch (e) {
            setSubmitProcess(false);
            setShowError(true);
        }
    };

    let hideCloseIcon = false;
    let dialogTitleLabel = <Trans>Submit?</Trans>;
    let submitActionLabel = mobile ? <Trans>Submit</Trans> : <Trans>Yes, submit</Trans>;
    let cancelActionLabel = mobile ? <Trans>Cancel</Trans> : <Trans>No, not yet</Trans>;
    const totalCount = interview?.questionCount + interview?.countScreeningQuestions;
    const answeredCount = currentQuestion ? (questions.length - incompletedQuestionsIndexes.length + interview?.countScreeningQuestions) : countAnswered;

    const returnIndexIncludingScreening = arr => arr.map(item => item + interview?.countScreeningQuestions);

    let dialogContentLabel = (
        mobile
            ? (
                <Trans i18nKey="submitDialog1" className={contentStyle}>
                    Well done, you’ve completed <strong>{{ ql: totalCount }}</strong> of <strong>{{ ql: totalCount }}</strong><br /> questions!
                    Do you want to submit<br /> the assessment for review?
                </Trans>
            )
            : (
                <Trans i18nKey="submitDialog2" className={contentStyle}>
                    Well done, you’ve completed <strong>{{ ql: totalCount }}</strong> of <strong>{{ ql: totalCount }}</strong> questions!<br />
                    Once you submit, you will no longer be able to change <br />your answers.
                </Trans>
            )
    );
    let dialogActionButtons = [
        <Button
            onClick={closeDialog}
            key={0}
            className={buttonStyle}
            classes={{ root: flatButton }}
            disabled={submitProcess}
        >
            { cancelActionLabel }
        </Button>,
        <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitInterview}
            key={1}
            className={buttonStyle}
            disabled={submitProcess}
        >
            {
                submitProcess
                    ? (
                        <>
                            {t('Submitting...')}
                            <CircularProgress size={18} className={circularProgress} />
                        </>
                    )
                    : submitActionLabel

            }
        </Button>
    ];

    if (incompletedRequiredQuestionsIndexes.length) {
        dialogTitleLabel = mobile ? <Trans>Submit</Trans> : <Trans>Please answer all required questions</Trans>;
        dialogContentLabel = (
            mobile
                ? (
                    <Trans i18nKey="submitDialog3" className={contentStyle}>
                        You haven’t answered all of the <br />required questions.
                        <br /><br />
                        You still need to answer the <br />following questions before <br />submitting:<br />
                        <strong>{{ indexes: returnIndexIncludingScreening(incompletedRequiredQuestionsIndexes).join(', ') }}.</strong>
                    </Trans>
                )
                : (
                    <Trans i18nKey="submitDialog4" className={contentStyle}>
                        You haven’t answered all of the required questions.
                        <br /><br />
                        You still need to answer the following questions before submitting:<br />
                        <strong>{{ indexes: returnIndexIncludingScreening(incompletedRequiredQuestionsIndexes).join(', ') }}.</strong>
                    </Trans>
                )
        );
        dialogActionButtons = [
            <Button
                variant="contained"
                color="primary"
                onClick={() => { closeDialog(); changeQuestion(incompletedRequiredQuestionsIndexes[0]); }}
                key={0}
                className={buttonStyle}
            >
                {t('Go to required questions')}
            </Button>
        ];
        hideCloseIcon = true;
    } else if (incompletedQuestionsIndexes.length) {
        submitActionLabel = mobile ? <Trans>I'm done, submit</Trans> : <Trans>Yes, submit</Trans>;
        cancelActionLabel = mobile ? <Trans>Go to unanswered questions</Trans> : <Trans>No, go to unanswered questions</Trans>;

        dialogTitleLabel = mobile ? <Trans>Submit</Trans> : <Trans>Submit with unanswered question/s?</Trans>;
        const isOnlyFeedbackLeft = incompletedQuestionsIndexes.length === 1
            && questions[incompletedQuestionsIndexes[0] - 1]
            && [NO_ANSWER, FEEDBACK].includes(questions[incompletedQuestionsIndexes[0] - 1].type);

        dialogContentLabel = (
            mobile ? (
                <Trans i18nKey="submitDialog5" className={contentStyle}>
                    You’ve answered {{ quantity: answeredCount }} out of {{ length: totalCount }} <br />
                    questions.  For best results, we <br/>
                    recommend answering all questions.<br/><br />

                    You still need to answer the<br/> following questions: <br />

                    <strong>{{ indexes: returnIndexIncludingScreening(incompletedQuestionsIndexes).join(', ') }}</strong>.
                </Trans>
            ) : (
                <div className={contentStyle}>
                    <Trans>You have not answered the following questions</Trans>:
                    <br />
                    <strong>{returnIndexIncludingScreening(incompletedQuestionsIndexes).join(', ')}</strong>
                    <br />
                    {
                        !isOnlyFeedbackLeft && (
                            <>
                                <br />
                                <Trans>This may result in a lower grade.</Trans>
                            </>
                        )
                    }
                </div>
            ));
        dialogActionButtons = [
            <Button
                onClick={handleSubmitInterview}
                key={0}
                disabled={submitProcess}
                className={buttonStyle}
                classes={{ root: flatButton }}
            >

                {
                    submitProcess
                        ? (
                            <>
                                {t('Submitting...')}
                                <CircularProgress size={18} className={circularProgress} />
                            </>
                        )
                        : submitActionLabel

                }
            </Button>,
            <Button
                variant="contained"
                color="primary"
                className={buttonStyle}
                onClick={() => { closeDialog(); changeQuestion(incompletedQuestionsIndexes[0]); }}
                key={1}
                disabled={submitProcess}
            >
                {cancelActionLabel}
            </Button>
        ];
    }

    return (
        <CustomDialog
            open={open}
            hideCloseIcon={hideCloseIcon}
            handleClose={closeDialog}
            classes={{ paper: dialogPaper }}
            title={<div className={titleStyle}>{dialogTitleLabel}</div>}
            content={(
                <>
                    {dialogContentLabel}
                    {
                        showError && (
                            <p className={errorMessage}>{t('Something went wrong. Try again.')}</p>
                        )
                    }
                </>
            )}
            actions={dialogActionButtons}
        />
    );
});
