import { lazy } from 'react';
import { retry, useLink } from 'src/utils/routing';
import { authStore } from 'src/store/auth';

import { InterviewPageStore } from './store';
import { ScreeningContentStore } from './screeningStore';

export default {
    name: 'interview',
    path: '/interview/:interviewId?token&id',
    activate: async (info) => {
        const goToStart = useLink('index', {});
        const goLogin = useLink('login');
        const { interviewId, token, id } = info.route.params;
        const goInterview = useLink('interview', { interviewId, token: undefined });

        if (token) {
            try {
                await authStore.magicLinkLogin({
                    accessToken: info.route.params.token,
                    hashCode: info.route.params.hashCode
                });
                goInterview();
            } catch (e) {
                goLogin();
                return;
            }
        }

        if (!interviewId) {
            goToStart();
            return;
        }

        InterviewPageStore.getInterview(interviewId, id, goToStart);
    },
    deactivate: () => {
        InterviewPageStore.cleanStore();
        ScreeningContentStore.cleanStore();
    },
    component: () => lazy(() => retry(() => import('./index')))
};
