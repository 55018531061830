import { lazy } from 'react';

import { retry } from 'src/utils/routing';

export default {
    name: 'change_email',
    path: '/change-email',
    secured: true,
    component: () => lazy(() => retry(() => import('.')))
};
