import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import { interviewApi } from '../../api/interview';
import { MULTIPLE_CHOICE, MULTIPLE_CHOICE_AUTO_GRADED, TEXT } from '../../utils/constants';
import { InterviewPageStore } from './store';
import { routerStore } from '../../store/router';


export const modifyScreeningQuestion = (question) => {
    if (!question) return null;
    const { singleChoice, text, fileType, ziggeoType, ziggeo, file } = question;
    const modified = { ...question };
    modified.question = {
        answerType: singleChoice ? MULTIPLE_CHOICE_AUTO_GRADED : MULTIPLE_CHOICE,
        type: +fileType || +ziggeoType || TEXT,
        description: text,
        ziggeo,
        file
    };
    modified.answerResults = modified.answers;
    return modified;
};

class ScreeningContent {
    @observable answerValue = null;

    @observable count = null;

    @observable loading = false;

    @observable saving = false;

    @observable countAnswered = null;

    @observable currentQuestion = null;

    @observable screeningStatus = 'screeningInProgress';

    @observable errorOnStart = false;

    timedIdForAnswerSavingDelay = null;

    @computed get currentQuestionIndex() {
        return this.countAnswered + 1;
    }

    @action getQuestions = async (interviewId) => {
        try {
            this.loading = true;
            const data = await interviewApi.getScreeningQuestions(interviewId);
            if (data.success && data.data) {
                const { questionResult, count, countAnswered } = data.data;
                this.count = count;
                this.countAnswered = countAnswered;
                this.currentQuestion = modifyScreeningQuestion(questionResult);
            }
            this.loading = false;
        } catch (e) {
            this.loading = false;
        }
    };

    @action goToNextQuestion = async () => {
        const { id } = this.currentQuestion;
        this.saving = true;
        try {
            const data = await interviewApi.saveScreeningAnswer(id, {
                answers: this.answerValue.filter(({ checked }) => checked).map(({ id: answerId }) => answerId)
            });

            if (data.success && data.data) {
                const { questionResult, count, countAnswered, status } = data.data;
                this.count = count;
                this.countAnswered = countAnswered;
                this.setScreeningStatus(status);
                this.answerValue = null;
                this.currentQuestion = modifyScreeningQuestion(questionResult);
            }
            this.saving = false;
        } catch (e) {
            this.saving = false;
            if (e.response && [410, 403].includes(e.response.status)) {
                this.cleanStore();
                this.errorOnStart = true;
            }
            console.log(e);
        }
    };

    @action setScreeningStatus = (status) => {
        this.screeningStatus = status;
        if (status === 'screeningPassed') {
            const { timerEnabled, audioAnswersIncluded, videoAnswersIncluded, id, brand } = InterviewPageStore.interview;
            const isAudioOrVideoIncluded = audioAnswersIncluded || videoAnswersIncluded;
            if (timerEnabled || isAudioOrVideoIncluded) {
                if (brand) InterviewPageStore.setAssessmentBranding(brand);
                routerStore.goTo({ name: 'equip_check', params: { auditionId: id } });
            } else {
                InterviewPageStore.getInterview(undefined, undefined, () => {
                    this.errorOnStart = true;
                });
            }
            this.cleanStore();
        }
    }

    @action cleanStore = () => {
        this.answerValue = null;
        this.count = null;
        this.loading = false;
        this.countAnswered = null;
        this.currentQuestion = null;
        this.saving = false;
        this.errorOnStart = false;
    };

    @action onChangeAnswer = (newAnswer) => {
        this.answerValue = newAnswer;
    };
}

export const ScreeningContentStore = new ScreeningContent();
export const ScreeningContentStoreCtx = createContext(ScreeningContentStore);
