import React from 'react';
import { secondsToTimeObject } from 'src/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const TimerLabel = ({ value, totalValue, xs = false, className, rightTimePart }) => {
    const { timerWrapper, timerWrapperLeft } = useStyles({});
    const { t } = useTranslation();
    const fifthValue = 0.2 * totalValue;

    const secondsToTime = (timeInSeconds) => {
        const addZeroIfNeeded = (value1) => {
            if (value1 < 10) return `0${value1}`;
            return value1;
        };
        const { days, hours, minutes, seconds } = secondsToTimeObject(timeInSeconds);

        if (days) {
            return `${days} ${xs ? t('D') : t('days')} ${hours ? `${hours} ${xs ? t('H') : t('hours')}` : ''}`;
        }

        if (hours) {
            return `${hours} ${xs ? t('H') : t('hours')} ${minutes ? `${minutes} ${xs ? t('M') : t('minutes')}` : ''}`;
        }

        return `${addZeroIfNeeded(minutes)}:${addZeroIfNeeded(seconds)}`;
    };

    return (
        <div
            className={`${timerWrapper} ${value <= fifthValue ? `${timerWrapperLeft}` : ''} ${className ? `${className}` : ''}`}
        >
            {rightTimePart && <span>{t('Time remaining')} &nbsp;</span>}
            {secondsToTime(value)}&nbsp;
            {!rightTimePart && t('remaining')}
        </div>
    );
};
