import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import Input from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
        maxWidth: '100%',

        '&:last-child': {
            marginBottom: 0
        }
    },
    counterLabel: {
        fontWeight: 600,
        fontSize: 10,
        textAlign: 'right',
        width: '100%',
        color: theme.palette.grey[500]
    },
    counterLabelError: {
        color: theme.palette.red.primary
    },
    helperTextWrapper: {
        display: 'flex',
        maxWidth: 'none',
        width: '100%',
        justifyContent: 'space-between'
    },
    helperText: {
        width: '100%'
    }
}));

export const TextField = ({
    variant = 'outlined', hasCounter,
    helperTextClassName = '',
    value, maxLength, helperText, errors, ...props
}) => {
    const classes = useStyles({});
    const { t } = useTranslation();


    const charactersRemaining = value ? maxLength - value.length : maxLength;

    const counterLabel = hasCounter ? (
        <span className={clsx(classes.counterLabel,
            charactersRemaining < 0 && classes.counterLabelError)}
        >
            {charactersRemaining} {t('characters remaining')}
        </span>
    ) : null;

    let formattedHelperText = (
        <>
            <span className={classes.helperText}>{helperText}</span>
            {counterLabel}
        </>
    );

    formattedHelperText = (helperText || counterLabel) ? formattedHelperText : null;

    return (
        <div className={classes.wrapper}>
            <Input
                value={value}
                inputProps={{ maxLength }}
                {...props}
                variant={variant}
            />
            {errors && (
                <FormHelperText error>
                    {t(errors[0])}
                </FormHelperText>
            )}
            {!errors && formattedHelperText && (
                <FormHelperText className={clsx(classes.helperTextWrapper, helperTextClassName)}>
                    {formattedHelperText}
                </FormHelperText>
            )}
        </div>
    );
};
