import { makeStyles } from '@mui/styles';
import { buildVariant } from 'src/utils/mui';

export const useStyles = makeStyles(theme => ({
    flatButton: {
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginTop: 16
        }
    },
    dialogPaper: {
        margin: 20,
        minWidth: 550,
        maxWidth: 650,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto'
        }
    },
    circularProgress: {
        position: 'absolute',
        right: 9,
        top: 9
    },
    [theme.breakpoints.down('sm')]: {
        titleStyle: {
            textAlign: 'center'
        },
        contentStyle: {
            textAlign: 'center'
        },
        buttonStyle: {
            width: '100%'
        }
    },
    errorMessage: {
        fontSize: '13px',
        color: theme.palette.red.primary,
        marginBottom: 0
    }
}));
