import { action, computed, observable, runInAction } from 'mobx';
import { createContext } from 'react';
import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';

import { routesInit } from '../routes';
import { InterviewPageStore } from '../pages/interview/store';
import { authStore } from './auth';

const routes = routesInit();


function makeMobxRouterPlugin(
    routesMap,
    routerStoreParam,
) {
    function mobxRouterPlugin() {
        return {
            onTransitionSuccess(nextStateArg, prevState) {
                const nextState = {
                    ...nextStateArg,
                    params: observable(nextStateArg.params)
                };

                const prevParams = (prevState || {}).params || {};
                const nextParams = nextState.params || {};
                const prevRoute = routesMap[(prevState || {}).name];
                const nextRoute = routesMap[nextState.name];

                if (prevRoute != null && prevRoute.deactivate != null
                    && prevState.name !== nextState.name
                    && typeof prevRoute.deactivate === 'function') {
                    prevRoute.deactivate(routerStoreParam, prevParams, nextState);
                }

                runInAction(() => {
                    if (!prevState || prevState.name !== nextState.name) {
                        routerStoreParam.route = nextState;
                        window.scrollTo(0, 0);

                        if (typeof nextRoute.activate === 'function') {
                            nextRoute.activate(routerStoreParam, nextParams, (prevState || {}));
                        }
                    } else if (typeof routerStoreParam.setRouteParams === 'function') {
                        routerStoreParam.setRouteParams(nextState);
                    }
                });
            }
        };
    }
    (mobxRouterPlugin).pluginName = 'MOBX_PLUGIN';

    return mobxRouterPlugin;
}

export function makeMobxRouter(routesMap, store) {
    const router = createRouter(Object.values(routes));

    router.usePlugin(
        browserPlugin(),
        makeMobxRouterPlugin(routes, store),
    );

    return router;
}

const defaultState = {
    name: 'not_found',
    params: {},
    path: '/not-found'
};

export class RouterStore {
    routes = routes;

    router = makeMobxRouter(this.routes, this);

    @observable route = observable(defaultState);

    @computed get params() {
        if (this.route) return this.route.params;

        return {};
    }

    @action setRouteParams = ({ params }) => {
        this.route.params = params;
    };

    goTo = ({ name, params }) => {
        authStore.clearAppStartColor();
        if (['index', 'job-dashboard', 'change_email', 'login'].includes(name)) {
            InterviewPageStore.assessmentBranding = null;
        }
        this.router.navigate(name, { ...this.params, ...params }, { replace: true });
    }
}

export const routerStore = new RouterStore();

export const RouterStoreCtx = createContext(routerStore);
