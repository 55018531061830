import { makeStyles } from '@mui/styles';
import { buildVariant } from '../../../../../../utils/mui';

export const useStyles = makeStyles(theme => ({
    submitButton: {
        marginLeft: 16,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15
        }
    },
    submitButtonLabel: {
        [theme.breakpoints.down('sm')]: {
            ...buildVariant(700, 12, 'textPrimary')
        }
    }
}));
