import { WrapperRequest } from './wrapperRequest';

export class Dashbooard extends WrapperRequest {
    getOpportunities() {
        return this.get('candidate/opportunities');
    }

    userAuditions(data) {
        return this.get(`candidate/opportunities/${data}/user-auditions`);
    }

    userAudition(id) {
        return this.get(`candidate/user-auditions/${id}`);
    }

    audition(hashCode) {
        return this.get(`public/opportunities/${hashCode}`);
    }

    moreTime(auditionId, reasonType, reason) {
        return this.patch(`/candidate/user-auditions/${auditionId}/reset-request`, {
            reasonType, reason
        });
    }
}

export const dashbooardApi = new Dashbooard();
