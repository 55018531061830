import React from 'react';

const VervoeLogo = () => (
    <svg width="107" height="18" viewBox="0 0 107 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M49.253 0.275832V4.78273C48.6689 4.62954 48.0687 4.54622 47.4651 4.53449C45.0775 4.53449 43.1521 5.97978 43.1521 9.17929V18.0055H38.2009V0.0551758H43.1521V2.38861C43.6373 1.65414 44.3009 1.05558 45.0804 0.649286C45.86 0.242992 46.7299 0.0423348 47.6081 0.0662086C48.1616 0.0867594 48.712 0.156913 49.253 0.275832Z" fill="#0C58B6" />
            <path d="M87.586 9.02474C87.586 11.4051 86.643 13.688 84.9644 15.3711C83.2859 17.0543 81.0093 17.9999 78.6354 17.9999C76.2616 17.9999 73.9849 17.0543 72.3064 15.3711C70.6278 13.688 69.6848 11.4051 69.6848 9.02474C69.6848 7.8461 69.9163 6.679 70.3661 5.59009C70.8159 4.50117 71.4752 3.51175 72.3064 2.67833C73.1375 1.84491 74.1242 1.1838 75.2102 0.732755C76.2961 0.28171 77.46 0.0495605 78.6354 0.0495605C79.8108 0.0495605 80.9747 0.28171 82.0606 0.732755C83.1466 1.1838 84.1333 1.84491 84.9644 2.67833C85.7956 3.51175 86.4549 4.50117 86.9047 5.59009C87.3545 6.679 87.586 7.8461 87.586 9.02474ZM74.5314 9.02474C74.5314 10.1162 74.9638 11.1629 75.7335 11.9346C76.5031 12.7064 77.547 13.14 78.6354 13.14C79.7238 13.14 80.7677 12.7064 81.5373 11.9346C82.307 11.1629 82.7394 10.1162 82.7394 9.02474C82.7394 7.93331 82.307 6.88659 81.5373 6.11483C80.7677 5.34307 79.7238 4.90951 78.6354 4.90951C77.547 4.90951 76.5031 5.34307 75.7335 6.11483C74.9638 6.88659 74.5314 7.93331 74.5314 9.02474Z" fill="#0C58B6" />
            <path d="M14.2263 0L9.64925 8.60558L5.06118 0.0165492L0 0L9.66576 17.9559L19.293 0H14.2263Z" fill="#0C58B6" />
            <path d="M65.1903 0L60.6132 8.60558L56.0251 0.0165492L50.9639 0L60.6297 17.9559L70.2569 0H65.1903Z" fill="#0C58B6" />
            <path d="M101.07 13.1123C100.315 13.653 99.3999 13.9231 98.473 13.879C97.4109 13.9322 96.3679 13.5818 95.5521 12.8977C94.7363 12.2136 94.2078 11.2462 94.072 10.1886H106.884C106.95 9.63694 106.989 8.815 106.989 8.42885C106.991 7.31478 106.773 6.21135 106.346 5.18262C105.92 4.15389 105.294 3.22035 104.505 2.43618C103.716 1.65201 102.779 1.03283 101.749 0.614591C100.719 0.196351 99.617 -0.0126236 98.506 -0.000202057C97.3312 -0.0201316 96.1642 0.196017 95.074 0.635498C93.9838 1.07498 92.9924 1.7289 92.1582 2.5587C91.324 3.38849 90.6639 4.37737 90.2169 5.46703C89.7699 6.55669 89.545 7.72506 89.5554 8.90326C89.5407 10.0998 89.7648 11.2871 90.2146 12.3955C90.6645 13.5038 91.3309 14.5107 92.1747 15.3568C93.0185 16.2029 94.0226 16.8712 95.1279 17.3222C96.2332 17.7733 97.4173 17.9981 98.6106 17.9832C100.186 17.985 101.739 17.6066 103.138 16.88L101.07 13.1123ZM98.319 4.02677C99.1767 3.98543 100.018 4.27052 100.675 4.82494C101.332 5.37937 101.756 6.1622 101.862 7.01665H94.3306C94.5341 6.12938 95.0437 5.34276 95.7695 4.79569C96.4952 4.24862 97.3904 3.97628 98.297 4.02677H98.319Z" fill="#0C58B6" />
            <path d="M30.2241 13.1124C29.4672 13.6539 28.5506 13.924 27.6219 13.8791C26.5598 13.9323 25.5168 13.5819 24.701 12.8978C23.8852 12.2137 23.3567 11.2463 23.2209 10.1887H36.0444C36.1112 9.60439 36.148 9.01703 36.1544 8.42895C36.1567 7.31394 35.9379 6.20959 35.5108 5.18013C35.0836 4.15067 34.4566 3.21662 33.6663 2.43231C32.8759 1.64799 31.9379 1.02905 30.9069 0.611468C29.8759 0.193888 28.7723 -0.0139984 27.6605 -0.000104131C26.4837 -0.0208249 25.3148 0.195291 24.2229 0.635461C23.1309 1.07563 22.1381 1.73092 21.303 2.56256C20.468 3.3942 19.8078 4.38531 19.3615 5.47727C18.9151 6.56922 18.6917 7.73985 18.7044 8.91991C18.6896 10.1169 18.9139 11.3047 19.3641 12.4134C19.8142 13.5221 20.4812 14.5292 21.3256 15.3754C22.1699 16.2216 23.1747 16.8898 24.2806 17.3405C25.3866 17.7912 26.5713 18.0154 27.765 17.9999C29.3406 18.0017 30.8935 17.6233 32.2925 16.8966L30.2241 13.1124ZM27.4514 4.02686C28.3091 3.98553 29.1507 4.27062 29.8076 4.82504C30.4646 5.37947 30.8884 6.16229 30.9942 7.01675H23.485C23.6885 6.12948 24.1982 5.34286 24.9239 4.79579C25.6497 4.24872 26.5449 3.97638 27.4514 4.02686Z" fill="#0C58B6" />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="107" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default VervoeLogo;
