export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const TEXT = 1;
export const MULTIPLE_CHOICE = 2;
export const AUDIO = 3;
export const VIDEO = 4;
export const ANY_FILE = 5;
export const IMAGE = 6;
export const NO_ANSWER = 7;
export const MULTIPLE_CHOICE_AUTO_GRADED = 8;
export const GOOGLE_DOC = 9;
export const PRESENTATION = 10;
export const SPREADSHEET = 11;
export const CODE_TEST = 12;
export const CODE_TEST_AUTOGRADED = 13;
export const FEEDBACK = 14;

export const SPREADSHEET_AUTO_GRADED = 16;
export const NUMBER = 17;
export const MULTIPLE_CHOICE_OLD = 18;
export const CS_SIMULATION = 19;
export const TYPING_TEST = 20;
export const NETWORK_SIMULATION = 21;
export const EXCEL_AUTO_GRADED = 22;
export const SALES_SIMULATION = 23;
export const NO_QUESTION = 24;
export const QA_SIMULATION = 25;
export const MARKETING_SIMULATION = 26;

export const SIMULATION_TYPES = [CS_SIMULATION, NETWORK_SIMULATION, SALES_SIMULATION, QA_SIMULATION, MARKETING_SIMULATION];

export const SCORE_TYPE_MANUAL = 'TYPE_MANUAL';
export const SCORE_TYPE_AI = 'TYPE_AI';


export const TYPE_PROSPECT_LIST = 2301;
export const TYPE_PROSPECT_SINGLE_CHOICE = 2302;
export const TYPE_PROSPECT_SINGLE_EMAIL = 2303;
export const TYPE_PROSPECT_SINGLE_EMAIL_CHOICE = 2304;
export const TYPE_PROSPECT_SINGLE_VIDEO = 2305;
export const TYPE_PROSPECT_SINGLE_AUDIO = 2306;
export const TYPE_PROSPECT_SINGLE_NOTE = 2307;
export const TYPE_PROSPECT_SINGLE_EMAIL_SUBJECT = 2308;
export const TYPE_PROSPECT_SINGLE_EMPLOYEE_SUBJECT = 2309;
export const TYPE_MARKET_EMAIL = 2310;
export const TYPE_MARKET_SINGLE_CHOICE = 2311;
export const TYPE_PROSPECT_LIST_TEXT = 2312;
export const TYPE_PROSPECT_LIST_EMAIL = 2313;
export const TYPE_PROSPECT_SINGLE_VOICE_MESSAGE = 2314;

export const TYPE_QA_TEST_CASE = 2501;
export const TYPE_QA_TEST_RUN = 2502;

export const QA_USER_STORY = 'QA_USER_STORY';
export const QA_TEST_CASE = 'QA_TEST_CASE';
export const QA_TEST_RUN = 'QA_TEST_RUN';
export const QA_CREATE_BUG = 'QA_CREATE_BUG';
export const QA_EDIT_BUG = 'QA_EDIT_BUG';

export const MARKETING_SIM_CREATE_ICP = 'MARKETING_SIM_CREATE_ICP';
export const MARKETING_SIM_FLOW_BUILDER = 'MARKETING_SIM_FLOW_BUILDER';
export const MARKETING_SIM_FLOW_CONTENT = 'MARKETING_SIM_FLOW_CONTENT';
export const MARKETING_SIM_FORECASTING_SHEET = 'MARKETING_SIM_FORECASTING_SHEET';
export const MARKETING_SIM_TEXT_WYSIWYG = 'MARKETING_SIM_TEXT_WYSIWYG';
export const MARKETING_SIM_COMPOSE_BUSINESS_PLAN = 'MARKETING_SIM_COMPOSE_BUSINESS_PLAN';
export const MARKETING_SIM_BUSINESS_PLAN_EMAIL = 'MARKETING_SIM_BUSINESS_PLAN_EMAIL';
export const MARKETING_SIM_SEO_TRAFFIC = 'MARKETING_SIM_SEO_TRAFFIC';
export const MARKETING_SIM_SEO_TECHNICAL = 'MARKETING_SIM_SEO_TECHNICAL';
export const MARKETING_SIM_SEO_SITEMAP = 'MARKETING_SIM_SEO_SITEMAP';
export const MARKETING_SIM_SEO_EMAIL_TO_MARKETING = 'MARKETING_SIM_SEO_EMAIL_TO_MARKETING';
export const MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS = 'MARKETING_SIM_SEO_DOCUMENT_SUGGESTIONS';
export const MARKETING_SIM_SEO_AUDIO_CALL = 'MARKETING_SIM_SEO_AUDIO_CALL';
export const MARKETING_SIM_SEO_SOLUTION_PAGE = 'MARKETING_SIM_SEO_SOLUTION_PAGE';
export const MARKETING_SIM_SEO_OFF_PAGE = 'MARKETING_SIM_SEO_OFF_PAGE';
export const MARKETING_SIM_SEO_COLD_OUTREACH = 'MARKETING_SIM_SEO_COLD_OUTREACH';
export const MARKETING_SIM_CM_STRATEGY = 'MARKETING_SIM_CM_STRATEGY';
export const MARKETING_SIM_CM_EMAIL = 'MARKETING_SIM_CM_EMAIL';
export const MARKETING_SIM_CM_TABLE = 'MARKETING_SIM_CM_TABLE';
export const MARKETING_SIM_CM_TEXT_WYSIWYG = 'MARKETING_SIM_CM_TEXT_WYSIWYG';
export const MARKETING_SIM_CM_AUDIO_CALL = 'MARKETING_SIM_CM_AUDIO_CALL';
export const MARKETING_SIM_CM_VIDEO_CALL = 'MARKETING_SIM_CM_VIDEO_CALL';
export const MARKETING_SIM_CM_SPREADSHEET = 'MARKETING_SIM_CM_SPREADSHEET';
