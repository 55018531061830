import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from 'src/assets/locales/es/translation.json';
import translationEN from 'src/assets/locales/en/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    }
};

i18n
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: 'en',
        returnEmptyString: false,
        saveMissing: true,
        fallbackLng: 'en',
        debug: false,
        nsSeparator: '_',
        keySeparator: false,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });


export default i18n;
