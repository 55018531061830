import { lazy } from 'react';

import { authStore } from 'src/store/auth';
import { useLink, setParams, retry } from 'src/utils/routing';
import { MagicLinkStore } from './store';

export default {
    name: 'login',
    path: '/login?token',
    activate: async (info) => {
        if (!info.route.params.token) {
            MagicLinkStore.loadingPage = false;
            return;
        }
        const goLogin = useLink('login');
        const goHome = useLink('index');

        try {
            await authStore.magicLinkLogin({
                accessToken: info.route.params.token,
                hashCode: info.route.params.hashCode
            });
            goHome();
            MagicLinkStore.loadingPage = false;
        } catch (e) {
            MagicLinkStore.loadingPage = false;
            MagicLinkStore.linkExpired = true;
            setParams(null);
            goLogin();
        }
    },
    component: () => lazy(() => retry(() => import('.')))
};
