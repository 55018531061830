import { WrapperRequest } from './wrapperRequest';

export class Auth extends WrapperRequest {
    fetchCompany() {
        return this.get('public/seeker/company');
    }

    loginUser(data) {
        return this.post('public/authorize', data);
    }

    sendMagicLink(data) {
        return this.post('public/candidates/login', data);
    }

    applyToJob(data) {
        return this.post('public/candidate/applytojob', data);
    }
}
export const authApi = new Auth();
