import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const RequiredLabel = ({ className }) => {
    const { wrapper } = useStyles({});
    const { t } = useTranslation();
    return (
        <div className={`${wrapper} ${className}`}>
            {t('Required')}
        </div>
    );
};
