import hexToRgba from 'hex-to-rgba';
import { useTheme, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import defaultColors from './colors';

export const colors = {
    textPrimary: '#3a3c43',
    textEmphasis: '#676a78',
    textSecondary: '#9fa0a5',
    textTertiary: '#1a1b1e',
    textQuaternary: '#606062',
    primary: '#1247F6',
    secondary: '#0A3EEA',
    success: '#69CD9D',
    disabled: '#EBECEE',
    neutral: '#ECEDEE',
    placeholder: '#9f9f9f',
    danger: '#f44336',
    borderPrimary: '#DDDEE2',
    globalBackground: '#FFFFFF',
    textSubtitle: '#3A465C',
    themeColor: '#65cbcb',
    logo: '#3f3f3f',
    tooltipColor: '#1f2032',
    recorderBorder: '#eeeeee'
};

export const buildVariant = (
    fontWeight,
    fontSize,
    colour
) => ({
    fontWeight,
    fontSize,
    color: colors[colour]
});

export const color = c => colors[c];
export const border = (width, colour, type = 'solid') => ({ border: `${width}px ${type} ${colors[colour]}` });


export const useWidth = (screen) => {
    const theme = useTheme();

    const width = ([...theme.breakpoints.keys].reverse().reduce(
        (output, key) => {
            const matches = useMediaQuery(theme.breakpoints.only(key));

            return !output && matches ? key : output;
        },
        null,
    ) || 'xs');

    const isLess = useMediaQuery(theme.breakpoints.down(screen)) || useMediaQuery(theme.breakpoints.only(screen));
    const isMore = useMediaQuery(theme.breakpoints.up(screen)) || useMediaQuery(theme.breakpoints.only(screen));

    return {
        width,
        isLess: Boolean(screen) && isLess,
        isMore: Boolean(screen) && isMore
    };
};

const themeInit = createTheme();

export const themeConfig = color2 => ({
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    palette: {
        ...defaultColors,
        primary: {
            main: color2.themeColor
        },
        secondary: {
            main: defaultColors.blue[600]
        }
    },
    typography: {
        color: defaultColors.grey[900],
        fontFamily: [
            'Open Sans'
        ].join(','),
        h2: buildVariant(600, 24),
        h3: buildVariant(600, 16),
        h4: buildVariant(400, 20),
        h5: buildVariant(600, 14),
        body1: buildVariant(600, 14, 'textPrimary'),
        subtitle1: buildVariant(400, 18, 'textPrimary'),
        subtitle2: buildVariant(600, 12, 'textSecondary'),
        caption: {
            ...buildVariant(400, 16, 'textEmphasis'),

            [themeInit.breakpoints.down('xs')]: {
                ...buildVariant(400, 14, 'textEmphasis')
            }
        },
        useNextVariants: true
    },
    components: {
        /* here you can rewrite global mui component styles */
        MuiCard: {
            styleOverrides: {
                root: {
                    border: `1px solid ${defaultColors.grey[300]}`,
                    boxShadow: 'none',
                    position: 'relative',
                    maxWidth: 757,
                    margin: '0 auto',
                    borderRadius: 4,
                    padding: 40,

                    [themeInit.breakpoints.down('xs')]: {
                        padding: 20
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,

                    '&:last-child': {
                        paddingBottom: 0
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                gutters: {
                    paddingLeft: 20
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '40%',
                    transition: 'translate(-50%)',
                    display: 'block',
                    margin: '0 auto'
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 43,
                    height: 43
                }
            }
        },
        MuiButton: {
            defaultProps: {
                color: 'inherit'
            },
            styleOverrides: {
                textSecondary: {
                    color: color('secondary'),
                    '&:hover': {
                        backgroundColor: `${hexToRgba(color('secondary'), 0.1)}`
                    }
                },
                textPrimary: {
                    color: color('themeColor'),
                    '&:hover': {
                        backgroundColor: `${hexToRgba(color('themeColor'), 0.1)}`
                    }
                },
                contained: {
                    backgroundColor: color('globalBackground'),
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#EBEBEB'
                    }
                },
                containedPrimary: {
                    backgroundColor: `${hexToRgba(color('themeColor'), 0.9)}`,
                    boxShadow: 'none',
                    ...buildVariant(700, 14, 'globalBackground'),

                    '&:hover': {
                        backgroundColor: color('themeColor')
                    },

                    '&:disabled': {
                        backgroundColor: color('themeColor'),
                        color: '#fff'
                    }
                },
                containedSecondary: {
                    boxShadow: 'none',
                    backgroundColor: color('secondary'),
                    color: '#fff',
                    ...buildVariant(700, 14, 'globalBackground'),
                    '&:hover': {
                        backgroundColor: color('secondary')
                    },
                    '&:disabled': {
                        backgroundColor: color('secondary'),
                        color: '#fff'
                    }
                },
                outlined: {
                    borderWidth: 2,
                    '&:hover': {
                        borderWidth: 2
                    },
                    '&:disabled': {
                        borderWidth: 2
                    }
                },
                outlinedSecondary: {
                    border: `2px solid ${colors.secondary}`,
                    ...buildVariant(700, 14, 'secondary'),
                    '&:hover': {
                        border: `2px solid ${colors.secondary}`,
                        color: colors.secondary
                    }
                },
                label: {
                    ...buildVariant(700, 14, 'textTertiary')
                },
                root: {
                    fontFamily: 'Open Sans',
                    textTransform: 'none',
                    minHeight: 40,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 3,
                    fontWeight: 700,

                    '&:disabled': {
                        opacity: 0.5
                    },
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                text: {
                    textTransform: 'none',
                    minHeight: 40,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 3,
                    color: 'inherit',
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Open Sans',
                    textTransform: 'none',

                    '&:disabled': {
                        opacity: 0.3
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    maxWidth: '100%',
                    [themeInit.breakpoints.down('xs')]: {
                        width: 335
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                padding: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Open Sans',
                    ...buildVariant(500, 14, 'textEmphasis'),
                    fontSize: 14,
                    fontWeight: 500,
                    padding: 0
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: `${color('themeColor')}`,

                    '&$checked': {
                        color: `${color('themeColor')}`
                    }
                },
                colorSecondary: {
                    color: `${color('secondary')}`,

                    '&$checked': {
                        color: `${color('secondary')}`
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    width: 500,

                    [themeInit.breakpoints.down('xs')]: {
                        width: 335,
                        marginRight: 0
                    }
                },
                label: {
                    ...buildVariant(400, 14, 'textEmphasis')
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#1f2032',
                    padding: '8px 20px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderRadius: 3,
                    border: 'solid 2px #c7c7c7'
                },
                root: {
                    backgroundColor: '#fff',
                    '&:hover': {
                        borderColor: color('themeColor')
                    }
                },
                multiline: {
                    padding: 14,
                    minHeight: 56
                },
                input: {
                    '&:disabled': {
                        '-webkit-text-fill-color': 'inherit'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontWeight: 500
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    ...buildVariant('normal', 14, 'textPrimary'),
                    '&::placeholder': {
                        ...buildVariant(400, 14, 'placeholder')
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    maxWidth: 500
                },
                h3: {
                    lineHeight: 1.33
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    maxWidth: 500,
                    fontSize: '0.75rem'
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: `${color('themeColor')}`,

                    '&$checked': {
                        color: `${color('themeColor')}`
                    }
                },
                colorSecondary: {
                    color: `${color('secondary')}`,

                    '&$checked': {
                        color: `${color('secondary')}`
                    }
                }
            }
        }
    }
});
