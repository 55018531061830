import React from 'react';
import clsx from 'clsx';

import { useLink } from 'src/utils/routing';

import AcmiLogo from './AcmiLogo.png';

import { useStyles } from './styles';

export const Logo = ({ appStartColor, assessmentLogo, isOnboardingAssessment, logo, company, disabled, classNameText = '' }) => {
    const { logoText, logoCorp, logoCorpDisabled } = useStyles({});
    const goToIndex = useLink('index', {});

    if (appStartColor) return null;

    let assessmentLogoSrc = assessmentLogo;

    if (isOnboardingAssessment && !assessmentLogoSrc) assessmentLogoSrc = AcmiLogo;

    if (assessmentLogoSrc || logo) {
        return (
            <img
                className={clsx(logoCorp, disabled && logoCorpDisabled)}
                onClick={!disabled ? goToIndex : null}
                src={assessmentLogoSrc || logo}
                alt="logo"
                title="logo"
            />
        );
    }

    return (
        <h2 className={clsx(logoText, classNameText)}>
            {company.company_name}
        </h2>
    );
};
