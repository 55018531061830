import { makeStyles } from '@mui/styles';
import { buildVariant } from '../../../../utils/mui';

export const useStyles = makeStyles({
    list: {
        width: 256
    },
    listComponent: {
        padding: 0
    },
    headOfSideBar: {
        paddingRight: 15,
        height: 60,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    textStyles: {
        ...buildVariant(600, 14, 'textEmphasis')
    },
    containerStyle: {
        backgroundColor: "transparent !important"
    }
});
