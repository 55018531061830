import 'date-fns';
import './index.css';
import React, { lazy, useContext } from 'react';
import ReactDOM from 'react-dom';
import { observer, useComputed } from 'mobx-react-lite';
import Snackbar from '@mui/material/Snackbar';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import { themeConfig, colors } from 'src/utils/mui';
import { authStore, authMiddleWare, AuthStoreCtx } from 'src/store/auth';
import { CenteredContent } from 'src/components/center_content';
import { CustomCircularProgress } from 'src/components/custom_circular_progress';
import NetworkError from 'src/pages/network_wrong';

import { routerStore, RouterStoreCtx } from './store/router';
import { AppStoreCtx } from './store/app';
import Layout from './components/layout';
import { InterviewPageStoreCtx } from './pages/interview/store';

const App = observer(() => {
    const { network_error, company, appStartColor } = useContext(AuthStoreCtx);
    const {
        snackbarOpened, snackbarAction,
        snackbarAutoHideDuration, snackbarText,
        closeSnackbar
    } = useContext(AppStoreCtx);
    const { assessmentBranding } = useContext(InterviewPageStoreCtx);

    const currentColor = (assessmentBranding && assessmentBranding.color) || appStartColor || company.company_color;
    colors.themeColor = currentColor;

    routerStore.router.useMiddleware(authMiddleWare(routerStore.routes));
    routerStore.router.start();

    const { RouteComponent } = useComputed(
        () => {
            if (routerStore.route) {
                const route = routerStore.routes[routerStore.route.name];

                if (!route) {
                    return {
                        RouteComponent: lazy(() => import('./pages/not_found'))
                    };
                }

                return {
                    RouteComponent: route && route.component()
                };
            }

            return {
                RouteComponent: lazy(() => import('./pages/not_found'))
            };
        },
        [routerStore.route],
    );

    const theme = createTheme(themeConfig(colors));

    if (network_error) {
        return <NetworkError/>;
    }

    return (
        <StyledEngineProvider injectFirst>
            <RouterStoreCtx.Provider value={routerStore}>
                <ThemeProvider theme={theme}>
                    <Layout>
                        <React.Suspense
                            fallback={(
                                <CenteredContent><CustomCircularProgress /></CenteredContent>
                            )}
                        >
                            {RouteComponent && <RouteComponent themeColor={currentColor}/>}
                        </React.Suspense>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            open={snackbarOpened}
                            autoHideDuration={snackbarAutoHideDuration}
                            onClose={closeSnackbar}
                            message={snackbarText}
                            action={snackbarAction}
                        />
                    </Layout>
                </ThemeProvider>
            </RouterStoreCtx.Provider>
        </StyledEngineProvider>
    );
});

if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            return child;
        }
        // eslint-disable-next-line prefer-rest-params
        return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            return newNode;
        }
        // eslint-disable-next-line prefer-rest-params
        return originalInsertBefore.apply(this, arguments);
    };
}

authStore.switchOn()
    .then(() => {
        ReactDOM.render(
            <App/>,
            document.getElementById('root')
        );
    })
    .catch(() => {
        // window.location.href = 'https://vervoe.com/';
    });
