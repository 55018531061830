import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';

import PoweredBy from 'src/assets/poweredby';
import { RouterStoreCtx } from '../../../store/router';
import { AppStoreCtx } from '../../../store/app';

const useStyles = makeStyles(theme => ({
    footer: {
        position: 'fixed',
        padding: 40,
        left: 0,
        bottom: 0,
        zIndex: 10,

        [theme.breakpoints.down('md')]: {
            padding: '0 21px 40px'
        }
    },
    powered: {
        width: 88,
        height: 28
    }
}));

export default observer(() => {
    const { powered, footer } = useStyles({});
    const { route } = useContext(RouterStoreCtx);
    const { isIFrame, isWebView } = useContext(AppStoreCtx);

    if (['not_found_link', 'not_found', 'attachment'].includes(route.name) || isIFrame || isWebView) return null;

    return (
        <footer className={footer}>
            <PoweredBy className={powered} />
        </footer>
    );
});
