import { routerStore } from 'src/store/router';
import { authStore } from 'src/store/auth';

export const useLink = (name, params) => () => routerStore.goTo({ name, params });
export const getParams = () => routerStore.params;
export const setParams = params => routerStore.setRouteParams({ params });


export function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        authStore.errorNetwork();
                        reject(error);
                        return;
                    }
                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
