export default ({
    info: {
        main: '#00a0fc'
    },
    blue: {
        50: '#E9F0FF',
        400: '#4260FF',
        600: '#0A3EEA',
        500: '#0C58B6',
        700: '#0032DD',
        800: '#0025D3',
        900: '#01253F',

        550: '#1247F6'
    },
    yellow: {
        primary: '#FFBB3C',
        600: '#FFBB3C',

        50: '#FFF9E4',
        200: '#FFEBDF',
        700: '#FDA938',
        800: '#FC9935',
        900: '#F87E30'
    },
    green: {
        primary: '#2FD1B9',
        300: '#2FD1B9',
        400: '#00C5A8',

        600: '#00B898',
        800: '#00876A',
        200: '#7ADFCE',
        50: '#E0F7F4'
    },
    red: {
        50: '#FFEBF0',
        primary: '#FF113D',
        500: '#FF334A',
        600: '#FF113D',

        400: '#FF4358',
        700: '#DB0032'
    },
    grey: {
        primary: '#4B4C50',
        800: '#4B4C50',

        50: '#FBFBFD',
        100: '#F8F8FA',
        200: '#F4F4F7',
        300: '#EBECEE',
        400: '#C7C8CC',
        500: '#A9AAAE',
        600: '#7F8084',
        700: '#6B6B70',
        900: '#292A2D'
    },
    purple: {
        400: '#6F59D9',
        500: '#503DD1',
        600: '#4438CB',
        900: '#001FAF'
    }
});
