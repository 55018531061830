import detect from 'browser-detect';

import Login from 'src/pages/login/magic_link/route';
import LoginNewUser from 'src/pages/login/new_user/route';
import LoginNewUserPreview from 'src/pages/login/new_user/route_preview';
import Home from 'src/pages/home_page/route';
import SubmitInterview from 'src/pages/submitted_interview/route';
import ChangeEmail from 'src/pages/change_email/route';
import VerifyEmail from 'src/pages/verify_email/route';
import EquipCheck from 'src/pages/equip_check/route';
import BrowserFail from 'src/pages/browser_fail/route';
import Interview from 'src/pages/interview/route';
import Admin from 'src/pages/admin/route';
import NotFound from 'src/pages/not_found/route';
import NotFoundLink from 'src/pages/not_found_link/route';
import Attachment from 'src/pages/attachment/route';

const browser = detect();

const commonRoutes = [
    Admin,
    Login,
    LoginNewUserPreview,
    LoginNewUser,
    Home,
    SubmitInterview,
    ChangeEmail,
    EquipCheck,
    Interview,
    VerifyEmail,
    NotFound,
    NotFoundLink,
    Attachment
];

export const routesInit = () => {
    if (window.location.pathname === '/') {
        window.location.href = '/job-dashboard';
    }
    if (browser.name === 'ie') {
        window.location.href = '/change-browser';
        return {
            [BrowserFail.name]: BrowserFail
        };
    }

    return commonRoutes.reduce(
        (prev, curr) => ({
            ...prev,
            [curr.name]: curr
        }),
        {}
    );
};
