import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    flatButton: {
        marginRight: 16,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginTop: 16
        }
    },
    paper: {
        margin: 20,
        width: 650,
        maxWidth: 650,
        [theme.breakpoints.down('sm')]: {
            width: 'auto'
        }
    },
    noWrap: {
        whiteSpace: 'nowrap'
    }
}));
