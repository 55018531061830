import { action, computed, observable } from 'mobx';
import { update } from 'serializr';
import { validateSync } from 'class-validator';

export class Validatable {
    @observable.ref errors = observable([]);

    constructor(props = {}) { this.deserialize(props); }

    @computed get err() {
        return this.errors.reduce(
            (acc, item) => ({
                ...acc,
                [item.property]: Object.values(item.constraints)
            }),
            {},
        );
    }

    getErrors = (keys) => {
        return keys.reduce(
            (acc, item) => [
                ...acc,
                ...(this.err[item] || [])
            ],
            []
        );
    };

    validateField = field => action(() => {
        this.errors.replace(this.errors.filter(i => i.property !== field));

        this.errors.replace(this.errors.concat(
            validateSync(this).filter(i => i.property === field),
        ));
    });

    @action validateFields = (fields) => {
        fields.forEach(f => this.validateField(f)());
    };

    @action deserialize = (patch) => {
        Object.keys(patch).forEach(key => this[key] = patch[key]);
    };

    @action update = patch => update(this, patch);

    @action resetErrors = () => {
        this.errors = observable([]);
    };

    createSetterFromComponent = key => action((e) => { this[key] = typeof e === 'string' ? e : e.target.value; });

    createBooleanSetterFromComponent = key => action((e) => { this[key] = e.target.checked; })
}
